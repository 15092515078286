const timezones = [
    {text: 'Araguaína (-02:00)', value: 'America/Araguaina'},
    {text: 'Bahia (-03:00)', value: 'America/Bahia'},
    {text: 'Belém, Santarém (-03:00)', value: 'America/Belem'},
    {text: 'Boa Vista (-03:00)', value: 'America/Boa_Vista'},
    {text: 'Campo Grande (-03:00)', value: 'America/Campo_Grande'},
    {text: 'Cuiabá (-03:00)', value: 'America/Cuiaba'},
    {text: 'Eirunepé (-03:00)', value: 'America/Eirunepe'},
    {text: 'Fernando de Noronha (-03:00)', value: 'America/Noronha'},
    {text: 'Fortaleza (-04:00)', value: 'America/Fortaleza'},
    {text: 'Maceió (-04:00)', value: 'America/Maceio'},
    {text: 'Manaus (-03:00)', value: 'America/Manaus'},
    {text: 'Porto Velho (-04:00)', value: 'America/Porto_Velho'},
    {text: 'Recife (-04:00)', value: 'America/Recife'},
    {text: 'Rio Branco (-04:00)', value: 'America/Rio_Branco'},
    {text: 'Santarém (-05:00)', value: 'America/Santarem'},
    {text: 'São Paulo, Brasília (-04:00)', value: 'America/Sao_Paulo'},
];

export default timezones;
